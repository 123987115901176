import React from "react";
import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url("/images/stars.jpg") repeat;
`;

const HomePage: React.FunctionComponent = () => {
  return <Container>hi. part 2 stuff</Container>;
};

export default HomePage;

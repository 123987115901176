import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import routes from "./routes";

import "./app.css";

const App: React.FunctionComponent<{}> = () => {
  return (
    <Router>
      <Switch>
        {routes.map(
          ({ path, exact = true, RouteComponent, noHeader = false }) => (
            <Route path={path} exact={exact} key={path}>
              <RouteComponent />
            </Route>
          ),
        )}
      </Switch>
    </Router>
  );
};

export default App;

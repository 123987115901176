import React from "react";
import styled from "styled-components";

import { useRouteControls } from "../../routes";

const A = styled.div`
  font-size: 400%;
  margin-top: 40px;
`;
const B = styled.div`
  font-size: 250%;
  margin-top: 20px;
`;
const C = styled.a`
  display: inline-block;
  margin-top: 30px;
  text-decoration: underline;
  font-size: 150%;
`;

const Error404: React.FunctionComponent = () => {
  const routeControls = useRouteControls();

  return (
    <div>
      <A>oh no!</A>
      <B>we couldn't find what you were looking for</B>
      <C onClick={routeControls.goTo.home}>go back home</C>
    </div>
  );
};

export default Error404;
